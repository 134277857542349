import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as XLSX from 'xlsx';
import { Utilities } from "../_common/utils";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsService } from "./data/reports.service";
import { MatPaginator } from "@angular/material/paginator";
import { SnackBarService } from "../_common/snackBar.service";

@Component({
    selector: 'app-kitework-transactions',
    templateUrl: './kiteworkReport.component.html',
    styleUrls: ['./kiteworkReport.component.css'],
})
export class KiteworkReportComponent  implements OnInit {

    displayedColumns: string[] = [
    '#','adCode', 'adName', 'reportingQualifier', 'valueDate', 'flow', 
    'trnReferenceNumber', 'sequence', 'subSequence', 'status', 
    'residentName', 'residentSurname', 'currency', 
    'randAmount', 'foreignAmount', 'bopCategory', 'subBopCategory'
   ];
   remittances = new MatTableDataSource<any>();
   form!: UntypedFormGroup;
   fromDate = new UntypedFormControl(new Date(), [Validators.required]);
   toDate = new UntypedFormControl(new Date(), [Validators.required]);
   // @ts-ignore
   @ViewChild(MatPaginator) paginator: MatPaginator;

   resultsLength = 0;
    constructor(
     private fb: UntypedFormBuilder,
     private reportService: ReportsService,
     private snackBar: SnackBarService,
  ) {
  }


  ngOnInit(): void {

    this.form = this.fb.group({
      remittanceCode: [null],
      ReportReference: [null],
      flow: [''],
      bopCategory: [''],
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }
  

    convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

   formatDate(date: Date) {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const year = d.getFullYear();
  
    return `${month}/${day}/${year}`;
}

 formatCustomString(dateFrom: Date, dateTo: Date): string {
  const year = dateFrom.getFullYear().toString();
  const lastThreeDigits = year.slice(-3);

  const startMonth = (dateFrom.getMonth() + 1).toString().padStart(2, '0');
  const startDay = dateFrom.getDate().toString().padStart(2, '0');
  const startDate = `${year}${startMonth}${startDay}`;

  // const endMonth = (dateTo.getMonth() + 1).toString().padStart(2, '0');
  const endMonth = (dateTo.getMonth() + 1).toString().padStart(2, '0');
  const endDay = dateTo.getDate().toString().padStart(2, '0');
  const endDate = `${year}${endMonth}${endDay}`;


  // Concatenate the parts with underscores and add "SARB"
  return `${138}_${startDate}_${endDate}_SARB`;
}



exportToExcel(): void {

    this.onSubmit().then(() => {

        if(this.remittances.data.length < 1){
            this.snackBar.open('No data to export.', 'Ok');
            return;
        }
    
        const dataToExport = this.remittances.data.map((row, index) => ({
        //   '#': index + 1,
          'Ad_Code': row.adCode,
          'Ad_Name': row.adName,
          'Reporting_Qualifier': row.reportingQualifier,
          'Value_Date': this.formatDate(row.valueDate),
          Flow: row.flow,
          'Trn_Reference_Number': row.trnReferenceNumber,
          Sequence: row.sequence,
          'Sub_Sequence': row.subSequence,
          Status: row.status,
          'Resident_Name': row.residentName,
          'Resident_Surname': row.residentSurname,
          Currency: row.currency,
          'Rand_Amount': row.randAmount,
          'Foreign_Amount': row.foreignAmount,
          'Bop_Category': row.bopCategory,
          'Sub_Bop_Category': row.subBopCategory,
        }));
    
    
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.formatCustomString(new Date(this.fromDate?.value), new Date(this.toDate?.value)));
        XLSX.writeFile(wb, this.formatCustomString(new Date(this.fromDate?.value), new Date(this.toDate?.value)) +  '.csv');

    }).catch((e) => {
      console.log(e)
    })

  }


onSubmit(
  ): Promise<any> {
      return new Promise((resolve) => {
    
	const { remittanceCode, ReportReference, flow, bopCategory,startDate, endDate } = this.form.value;
    const filter: any = {};
    if(remittanceCode) filter.remittanceCode = remittanceCode;
    if(ReportReference) filter.ReportReference = ReportReference;
    if(flow) filter.flow = flow;
    if(bopCategory) filter.bopCategory = bopCategory;
    if(startDate) filter.startDate = startDate;
    if(endDate) filter.endDate = endDate;

      this.reportService
        .getKiteworkTransactions(
          filter,
        )
        .subscribe((data) => {
          this.remittances.data = data;
          this.remittances.paginator = this.paginator;
		  resolve(data); 
        }, ({ message }) => {
		
          this.snackBar.open(message);
		  resolve(null);
        });

      });
  }

}